export { wrapRootElement } from "./src/RootElement";
export const onRouteUpdate = ({ location, prevLocation }) => {
	if (typeof window !== "undefined") {
		// Retrieve the last valid location from session storage
		const lastValidLocation =
			window.sessionStorage.getItem("lastValidLocation");

		// Update the lastVisitedPage with prevLocation if it exists, otherwise use lastValidLocation
		if (prevLocation) {
			window.sessionStorage.setItem("lastVisitedPage", prevLocation.pathname);
		} else if (lastValidLocation) {
			window.sessionStorage.setItem("lastVisitedPage", lastValidLocation);
		}

		// Update the lastValidLocation with the current location's pathname
		window.sessionStorage.setItem("lastValidLocation", location.pathname);

		// WEB CHAT HOOK
		// Without this, the web chat script won't execute unless you refresh the page
		// The window.__8x8Chat object is not available on route switch and differs for different pages
		// Therefore, we need to access the script tag and extract the object from it
		const loadWebChat = () => {
			// We don't want to run the script multiple times
			if (window.__8x8Chat) {
				console.log(
					"Web chat has successfully loaded from the CMS embedded widget",
				);
				return;
			}
			// This class is added in the CMS embedded widget
			const hasWebchat = document.querySelector(".webChatButton");
			// This ID is added to the script tag in the CMS embedded widget
			const scriptElement = document.getElementById("web-chat-script");
			// Extract the window.__8x8Chat object from the script tag
			const regex = /window\.__8x8Chat\s*=\s*(\{[\s\S]*?\});/;
			const scriptContent = scriptElement
				? scriptElement.textContent.match(regex)
				: null;

			if (!hasWebchat || !scriptContent) {
				console.log("Web chat was not found or isn't setup correctly");
				return;
			}

			// We need to make sure the window.__8x8Chat object in the CMS embedded widget is a valid JSON object
			// ------------------------------------------------------
			let windowStringObject = scriptContent[1];
			// Add double quotes to only the keys (prevents https:// from being double quoted)
			windowStringObject = windowStringObject.replace(
				/(\w+)(?=\s*:)(?!:\/\/)/g,
				'"$1"',
			);
			// Remove trailing commas (inside the object, before closing brace)
			windowStringObject = windowStringObject.replace(/,\s*(?=\s*[\}\]])/g, "");

			try {
				// Create the window.__8x8Chat object so our chat script can run
				window.__8x8Chat = JSON.parse(windowStringObject);
			} catch (e) {
				console.error("Invalid JSON format for 8x8Chat:", e);
				return;
			}

			if (!window.__8x8Chat) {
				console.log("Web chat has failed to load from the code");
				return;
			}

			const script = document.createElement("script");
			script.type = "text/javascript";
			script.async = true;

			console.log("Web chat has loaded successfully from the code");
			script.src = `${window.__8x8Chat.domain}${window.__8x8Chat.path}/CHAT/common/js/chat.js`;

			const os = document.getElementsByTagName("script")[0];

			os.parentNode.insertBefore(script, os);

			return () => {
				if (script.parentNode) {
					script.parentNode.removeChild(script);
				}
			};
		};

		loadWebChat();
	}
};
